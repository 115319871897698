div.col-4 {
  column-width: 15em;
  column-count: 4;
  column-gap: 1em;
  column-rule: 1px dotted #ddd;
}

i.fa-colour-beige {
  color: beige;
}

i.fa-colour-black {
  color: black;
}

i.fa-colour-blue {
  color: blue;
}

i.fa-colour-brown {
  color: brown;
}

i.fa-colour-cyan {
  color: cyan;
}

i.fa-colour-fuchsia {
  color: fuchsia;
}

i.fa-colour-gold {
  color: gold;
}

i.fa-colour-gray {
  color: gray;
}

i.fa-colour-green, i.enabled {
  color: green;
}

i.fa-colour-magenta {
  color: magenta;
}

i.fa-colour-navy {
  color: navy;
}

i.fa-colour-olive {
  color: olive;
}

i.fa-colour-orange, i.disabled {
  color: orange;
}

i.fa-colour-pink {
  color: pink;
}

i.fa-colour-purple {
  color: purple;
}

i.fa-colour-red, i.deletable {
  color: red;
}

i.fa-colour-silver {
  color: silver;
}

i.fa-colour-teal {
  color: teal;
}

i.fa-colour-violet {
  color: violet;
}

i.fa-colour-white {
  color: white;
}

i.fa-colour-yellow {
  color: yellow;
}

.disable_a_href {
  pointer-events: none;
}

.sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
  font-size: 18px !important;
}

.login-logo img.fallback-icon {
  position: relative;
}

.login-logo img.fallback-icon:before {
  content: '\f090';
  display: block;
  background-color: #ecf0f5;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.main-header > .navbar {
  display: flex;
}

.main-header .heading {
  margin: 0 auto;
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 24px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}

.main-footer {
  display: none;
  height: 0;
  padding: 0;
  margin: 0;
}

.bootstrap-tagsinput {
  width: 100%;
}

/*# sourceMappingURL=app.css.map */
